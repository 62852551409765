// Route guard middleware for games - using user feature to detect if user features include games
// Work in combination with page:finish hook in plugins/hooks.client.js
export default defineNuxtRouteMiddleware(async() => {
  const userStore = useUserStore();
  const {
    userFeatures,
  } = storeToRefs(userStore);
  const { getUserData, } = userStore;
  const uiStore = useUiStore();
  const {
    redirecting,
    errorModalType,
    showErrorModal,
    allowModalsOnPageLoad,
  } = storeToRefs(uiStore);

  if (redirecting.value) { return; }

  if (!userFeatures.value) {
    await getUserData();
  }

  if (!userFeatures.value?.games) {
    errorModalType.value = 'GamesBlocked';
    showErrorModal.value = true;
    allowModalsOnPageLoad.value = true;
  }
});
